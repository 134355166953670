import {
  ORDER_INFO_FETCH_SUCCESS,
  ORDER_INFO_FETCH_FAIL,
  ORDER_INFO_UPDATE_SUCCESS,
  ORDER_INFO_UPDATE_FAIL,
} from "../actions/orderInfoTypes";

const initialState = {
  orderInfo: [],
  error: null,
  update: null,
};

const orderInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_INFO_FETCH_SUCCESS:
      return {
        ...state,
        orderInfo: action.payload.data,
        error: null,
        update: null,
      };
    case ORDER_INFO_FETCH_FAIL:
      return {
        ...state,
        error: action.payload.data,
        update: null,
      };
    case ORDER_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        error: null,
        update: null,
      };
    case ORDER_INFO_UPDATE_FAIL:
      return {
        ...state,
        error: action.payload.data,
        update: null,
      };
    default:
      return state;
  }
};

export default orderInfoReducer;

export const getOrderInfo = (state) => state.orderInfoReducer.orderInfo;
