import {
  ORDER_FETCH_SUCCESS,
  ORDER_FETCH_FAILURE,
} from "../actions/orderActionTypes";

const initialState = {
  orders: [],
  error: null,
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDER_FETCH_SUCCESS:
      return {
        ...state,
        orders: action.payload.data,
        error: null,
      };
    case ORDER_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default orderReducer;

export const getOrders = (state) => state.orderReducer.orders;
