import { combineReducers } from "redux";
import productReducer from "./productReducer";
import orderInfoReducer from "./orderInfoReducer";
import orderReducer from "./orderReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["productReducer", "orderInfoReducer"],
};

const rootReducer = combineReducers({
  productReducer: productReducer,
  orderReducer: orderReducer,
  orderInfoReducer: orderInfoReducer,
});

export default persistReducer(persistConfig, rootReducer);
