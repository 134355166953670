import {BreadcrumbsProvider} from "react-breadcrumbs-dynamic";
import Urls from "./Urls";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/*<Topbar />*/}
        <BreadcrumbsProvider>
          <Urls/>
        </BreadcrumbsProvider>
      </header>
    </div>
  );
}

export default App;
