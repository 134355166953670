let API_SERVER_VAL = "";
let PUBLIC_URL_VAL = "";
let MEDIA_SERVER_VAL = "";

switch (process.env.NODE_ENV) {
  case "development":
    API_SERVER_VAL = "http://localhost:8000";
    MEDIA_SERVER_VAL = "http://localhost:8000";
    PUBLIC_URL_VAL = "http://localhost:3000";
    break;
  case "production":
    API_SERVER_VAL = process.env.REACT_APP_API_SERVER;
    MEDIA_SERVER_VAL = process.env.REACT_APP_API_SERVER;
    PUBLIC_URL_VAL = process.env.REACT_APP_API_SERVER;
    //if env. does not continue with REACT_APP env variables does not work written on the documentation
    break;
  default:
    API_SERVER_VAL = "http://localhost:8000";
    MEDIA_SERVER_VAL = "http://localhost:8000";
    PUBLIC_URL_VAL = "http://localhost:3000";
    break;
}

export const API_SERVER = API_SERVER_VAL;
export const PUBLIC_URL = PUBLIC_URL_VAL;
export const MEDIA_SERVER = MEDIA_SERVER_VAL;

export const SESSION_DURATION = 5 * 3600 * 1000;
