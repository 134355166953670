import React, {lazy, Suspense} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ScrollToTop from "./helpers/scroll-top";
import Login from "./pages/login/Login";

const Home = lazy(() => import("./pages/home/Home"));
const Users = lazy(() => import("./pages/userlist/UserList"));
const User = lazy(() => import("./pages/user/User"));
const NewUser = lazy(() => import("./pages/newuser/NewUser"));
const ProductList = lazy(() => import("./pages/productlist/ProductList"));
const Product = lazy(() => import("./pages/product/Product"));
const NewProduct = lazy(() => import("./pages/newproduct/NewProduct"));
const AddNewProduct = lazy(() => import("./pages/newproduct/AddNewProduct"));
const Amazon = lazy(() => import("./pages/amazon/Amazon"));
const Visual = lazy(() => import("./pages/visual/Visual"));
const Order = lazy(() => import("./pages/orders/order/Order"));
const Orders = lazy(() => import("./pages/orders/Orders"));
const Excel = lazy(() => import("./pages/excel/Excel"));
const Plugin = lazy(() => import("./pages/plugin/Plugin"));
const SignIn = lazy(() => import("./pages/signIn/SignIn"));

function Urls(props) {
  return (
    <div>
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="Epica-preloader-wrapper">
                <div className="Epica-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Switch>
              <Route
                exact
                path={process.env.PUBLIC_URL + "/login"}
                component={Login}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/"}
                component={Login}
                // component={Home}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/users"}
                component={Users}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/user/:userId"}
                component={User}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/newUser"}
                component={NewUser}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/products"}
                component={ProductList}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/product/:userId"}
                component={Product}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/order/:userId"}
                component={Order}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/newproduct"}
                component={NewProduct}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/add-new-product"}
                component={AddNewProduct}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/amazon"}
                component={Amazon}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/visual"}
                component={Visual}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/orders"}
                component={Orders}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/excel"}
                component={Excel}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/plugin"}
                component={Plugin}
              />
              <Route
                exact
                path={process.env.PUBLIC_URL + "/signin"}
                component={SignIn}
              />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default Urls;
