import * as settings from "../settings";
import axios from '../helpers/axios';


export const getAllTheProducts = () => {
  return axios.get(`${settings.API_SERVER}/api/products`)
    .then(response => {
      return response.data
    })
    .catch((error) => {
      const errorMsg = error.message;
      console.log(errorMsg);
      console.log("error");
      return errorMsg;
    });
}